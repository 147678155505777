
import { defineComponent } from "vue";
import KycVerificationIndexList from "@/components/page/kyc/kyc-verification/IndexList.vue";

export default defineComponent({
  name: "kyc-verification-legal-entity",
  components: {
    KycVerificationIndexList,
  },
  setup() {
    return {};
  },
});
